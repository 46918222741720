/*
	Adjusting sizes
*/

@media (max-width: 768px) {
    body {
        //background-image:url('../img/background.png');
        //background-repeat: no-repeat;
        //background-attachment: fixed;
        //background-size: 100%;
    }
    .hero,
    .signature,
    .limited-editions,
    .gift-wrap {
        width: 80%;
    }
    .limited-editions {
        &__products {
            &--fleft {
                width: 100%;
                float: left;
            }
            &--fright {
                width: 100%;
                float: right;
            }
        }
    }
    .hero__title {
        font-size: 1.7em;
    }
    .flex-row {
        .title {
            font-size: 1.7em;
        }
    }
    .limited-editions__title,
    .gift-wrap__title,
    {
        font-size: 1.25em;
    }
    .gift-wrap__title,
    .gift-wrap__content {
        width: 100%;
    }
    .featured {
        &__product {
            &__desc {
                padding: 0 10%;
            }
            &:before {
                background-size: 80%;
            }
        }
    }
}

@media (min-width: 651px) and (max-width:850px) {
    .onimg {
        min-height: 200px;
        .overlay {
            height: 95%;
        }
    }
}

@media (max-width: 650px) {
    .onimg:hover {
        img {
            -webkit-filter: grayscale(0%)!important;
            /* Safari */
            filter: grayscale(0%);
            transition: all 0.4s linear;
        }
        .overlay {
            opacity: 0;
            transition: all 0.4s linear;
            display: none;
        }
    }
    .flex-row {
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
    }
    .reverse {
        .flex-row {
            flex-direction: column-reverse!important;
        }
    }
    .reverse-mobile {
        .flex-row {
            flex-direction: column-reverse!important;
        }
    }
    .span_2_of_2 {
        width: 100%;
    }
    .span_1_of_2 {
        width: 100%;
    }
    /*  GRID OF THREE  */
    .span_3_of_3 {
        width: 100%;
    }
    .span_1_of_3 {
        width: 100%;
    }
    .span_2_of_3 {
        width: 100%;
    }
    .span_1_of_3_s {
        width: 100%;
    }
    .span_2_of_3_s {
        width: 100%;
    }
    .col {
        display: block;
        float: left;
        margin: 1%!important;
        margin-top: 10%!important;
        &:first-child {
            margin-left: 1%;
        }
    }
    .grid__product__content {
        max-width: 100%;
    }
    .no-padding {
        .hero__product__desc {
            padding-bottom: 40px;
        }
    }
    .hero__product__desc__no_pad {
        padding: 0px;
    }
    .header {
        p {
            padding: 0px;
            padding-top: 0px;
        }
    }
    .margin {
        .span_1_of_2 {
            width: 100%;
        }
        .col {
            margin-top: 00% !important;
        }
    }
}