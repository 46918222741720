/*  SECTIONS  */

.section {
    clear: both;
    padding: 0px;
    margin: 1% 0px;
}


/*  COLUMN SETUP  */

.col {
    display: block;
    float: left;
    margin: 1% 0 1% 2%;
}

.col:first-child {
    margin-left: 0;
}

.col:last-child {
    margin-right: 0;
}


/*  GROUPING  */

.group:before,
.group:after {
    content: "";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    zoom: 1;
    /* For IE 6/7 */
}


/*  GRID OF TWO  */

.span_2_of_2 {
    width: 100%;
}

.span_1_of_2 {
    width: 49%;
}


/*  GRID OF THREE  */

.span_3_of_3 {
    width: 100%;
}

.span_1_of_3 {
    width: 30%;
}

.span_2_of_3 {
    width: 63%;
}

.span_1_of_3_s {
    width: 32%;
}

.span_2_of_3_s {
    width: 52%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}