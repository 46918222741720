// Colors
$white : #f7f7f7;
$black : #000;

//$bezier : cubic-bezier(0.165, 0.84, 0.44, 1);

// Variables
$defaultFontSize : 16;

// Sizes
$innerWidth : 1300px;
$innerWidthRaw : 1300;
$mastheadRawWidth: 1132;


// Media queries : Breaks
$break-sm : 480px;
$break-mobilemenu : 680px;
$break-md : 870px;
$break-lg : 1366px;
