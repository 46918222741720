/* roboto-regular - latin */

@font-face {
    font-family: 'Berthold Light';
    font-style: normal;
    font-weight: 400;
    src: local('Berthold Light'), local('Berthold Light'), url('../fonts/Berthold-Akzidenz-Grotesk-Light-OsF.ttf') format('truetype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/Berthold-Akzidenz-Grotesk-Light-OsF.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/Berthold-Akzidenz-Grotesk-Light-OsF.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/Berthold-Akzidenz-Grotesk-Light-OsF.otf') format('opentype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}


/* roboto-700 - latin */


/* roboto-regular - latin */

@font-face {
    font-family: 'Berthold Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Berthold Bold'), local('Berthold Bold'), url('../fonts/AkzidenzGroteskBE-Bold.ttf') format('truetype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/AkzidenzGroteskBE-Bold.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/AkzidenzGroteskBE-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/AkzidenzGroteskBE-Bold.otf') format('opentype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}


/* roboto-700 - latin */