body {
    background: $white;
    //Font family and size
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: $black;
    width: 100%;
    max-width: $innerWidth;
    margin: 0 auto;
    //background-image:url('../img/background.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    line-height: 1.3;
    font-size: 16px;
}

.name {
    font-family: Arial, Helvetica, sans-serif;
}

.abold {
    font-weight: 900;
}

#header,
.logo,
.header,
.hero,
.grid {
    width: 84%;
    margin: 0 auto;
}

.sep {
    &:before {
        content: "";
        display: block;
        border-bottom: 1px solid black;
        width: 100%;
        margin: 0 auto;
        height: 20px;
    }
}

.logo {
    display: block;
    text-align: center;
    img {
        margin: 0 auto;
    }
    margin-top: 40px;
    margin-bottom: 40px;
}

img {
    max-width: 100%;
}

p {
    margin: 0 auto;
    padding: 30px 0;
    //letter-spacing: 1px;
    font-size: 16px;
}

.cta {
    display: block;
    background: transparent;
    color: $black;
    padding: 15px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 auto;
    max-width: 150px;
    border: 1px solid $black;
    text-align: center;
    &__big {
        max-width: 300px;
    }
}

.container {
    z-index: 1;
    //max-width: $innerWidth;
    //margin: 0 auto;
    position: relative;
}

.header {
    p {
        padding-top: 30px;
    }
    img {
        margin-bottom: -5px;
        width: 100%;
    }
}

.hero {
    margin-bottom: 50px;
    p {
        padding-top: 30px;
    }
    &__title {
        text-align: center;
        margin: 0 auto;
        padding: 50px 0px 30px;
        &__nomargin {
            padding-bottom: 0px;
        }
        font-size: 2.75em;
        text-transform: uppercase;
        font-weight: 700;
    }
    &__subtitle {
        text-align: center;
        padding-top: 0px!important;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.050em;
    }
    &__product {
        background-color: #fff;
        padding: 40px;
        //width:100%;
        display: block;
        //height: 500px;
        position: relative;
        &__desc {
            text-align: center;
            padding: 0 20%;
            .title {
                text-transform: uppercase;
                text-align: center;
                margin: 10px;
            }
            .content {
                padding: 10px 0px 40px;
                text-align: center;
                p {
                    margin: 15px;
                }
                &__big {
                    margin: 20px 0px;
                    padding: 0px;
                    padding-top: 0px!important;
                    b {
                        display: block;
                        padding: 2px;
                    }
                }
            }
        }
    }
    .add-flag {
        &:before {
            content: "";
            display: block;
            background-image: url('../img/best-seller.png');
            max-width: 206px;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
            position: absolute;
            height: 155px;
            width: 100%;
            background-position: top right;
            background-size: 95%;
        }
    }
    .no-padding {
        padding: 0px;
        .img {
            background-image: url('../img/noel.png');
            background-size: cover;
            background-position: top center;
            margin: 0%;
            line-height: 0;
            min-height: 450px;
            margin: 0%;
            background-repeat: no-repeat;
            height: 100%;
            padding-bottom: 4%;
            display: block;
        }
        text-align: center;
        .col {
            margin: 0%!important;
        }
    }
    .add-bg {
        background-image: url('../img/background-noel.png');
        background-repeat: repeat-x;
        background-position: top right;
        background-size: contain;
        .hero__product__desc {
            .signature {
                margin: 75px 0;
            }
            .content {
                &:after {
                    content: "";
                    display: block;
                    width: 50%;
                    margin: 0 auto;
                    height: 40px;
                    border-bottom: 1px dotted black;
                }
            }
            .disclaimer {
                padding: 0px;
                font-size: 11px;
                margin-top: 10px;
            }
        }
    }
}

.grid {
    margin-bottom: 80px;
    .col {
        margin: 1% 0 1% 5%;
    }
    &__product {
        .onimg {
            display: block;
            position: relative;
        }
        img {
            width: 100%;
            -webkit-filter: grayscale(0%);
            /* Safari */
            filter: grayscale(0%);
            transition: all 0.4s linear;
        }
        .overlay {
            height: 98%;
            position: absolute;
            background: rgba(247, 247, 247, 0.9);
            top: 0px;
            /* width: 100%; */
            max-width: unset;
            display: flex;
            flex-direction: column;
            /* vertical-align: sub; */
            justify-content: center;
            opacity: 0;
            transition: all 0.4s linear;
            .content {
                min-height: unset!important;
            }
        }
        .onimg:hover {
            img {
                -webkit-filter: grayscale(50%);
                /* Safari */
                filter: grayscale(50%);
                transition: all 0.4s linear;
            }
            .overlay {
                opacity: 1;
                transition: all 0.4s linear;
            }
        }
        &__content {
            margin-top: 0px;
            .title {
                margin-bottom: 15px;
                margin-top: 0px;
                text-transform: uppercase;
            }
            .content {
                min-height: 128px;
                padding: 5px;
                margin-bottom: 0px;
            }
            .price {
                margin-top: 0px;
                margin-bottom: 10px;
                font-weight: 700;
                padding: 0px;
                span {
                    font-weight: 400;
                    display: block;
                }
            }
            max-width: 240px;
            text-align: center;
        }
    }
}

.reversed {
    margin-top: 40px;
}

.reverse {
    .hero__product__desc {
        padding: 0%;
    }
    .hero__product__desc__pad {
        padding: 0 20%;
    }
    .flex-row {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .col {
        margin: 1% 2%;
        &:first-child {
            margin-left: 2%;
        }
        &:last-child {
            margin-right: 2%;
        }
    }
    .title {
        text-align: center;
        margin: 0 auto;
        padding: 50px 0;
        font-size: 2.50em;
        text-transform: uppercase;
        padding-bottom: 0px;
    }
}

.content__big {
    font-size: 16px;
}

.no-mg {
    margin: 0!important;
}

.margin {
    .span_1_of_2 {
        width: 50%;
    }
    .col {
        margin: 0% 0 0% 2%;
        img {
            width: 100%;
            margin: 0!important;
            padding: 0!important;
            margin-bottom: -5px!important;
        }
    }
}

.placeholder {
    height: 30px;
}