body {
    background: #f7f7f7;
}

#header {
    max-width: 1300px;
    text-align: center;
    margin: 0 auto;
}

ul {
    font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    font-size: 14px;
    font-style: normal;
    text-transform: uppercase;
    list-style-type: none;
    list-style-position: outside;
}

#logo {
    max-width: 231px;
    float: left;
    min-height: 50px;
    width: 15%;
}

.sep {
    &:before {
        content: "";
        display: block;
        border-bottom: 1px solid black;
        width: 100%;
        margin: 0 auto;
        height: 20px;
    }
}

#logo img {
    min-width: 115px;
    width: 100%;
    min-height: 50px;
}

#menu_margiela {
    width: 85%;
    float: left;
    display: block;
    margin: 0;
    padding: 0;
}

#menu_margiela li {
    display: inline-block;
    padding: 0px 1%;
    padding-top: 30px;
}

#menu_margiela li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 0px dashed black;
    transition: all 0.1s linear;
}

#menu_margiela li a:hover {
    border-bottom: 1px dashed black;
    transition: all 0.1s linear;
}

#menu_margiela li .active {
    border-bottom: 1px dashed black;
    transition: all 0.1s linear;
}

@media (min-width: 651px) and (max-width:1100px) {
    #logo {
        width: 25%;
    }
    #menu_margiela {
        width: 75%;
        float: right;
    }
}

@media (min-width: 301px) and (max-width:650px) {
    #menu_margiela li {
        display: block;
        padding-top: 10px;
        text-align: center;
        width: 50%;
        margin: 0 auto;
    }
    #menu_margiela {
        width: 100%;
        float: none;
        display: block;
        margin: 0;
        padding: 0;
        margin-left: 0px;
    }
    #logo {
        max-width: unset;
        float: none;
        min-height: 50px;
        width: 50%;
        text-align: center;
        margin: 0 auto;
    }
}